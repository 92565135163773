import React from "react";
import { onReadMore } from "./Functions";

import Commcu1 from "./images/commcu1.gif";
import Commcu2 from "./images/commcu2.gif";
import Commcu3 from "./images/commcu3.gif";
import TreeTrunkwithHoles from "./images/TreeTrunkwithHoles.png";
import Logs_stacked from "./images/Logs_stacked.jpg";
import logs_end from "./images/logs_end.png";
import inoculatorinhand from "./images/inoculatorinhand.jpg";
import workbench from "./images/workbench-set-up.jpg";

const CultivationSawdust = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="cultivation-techniques-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						INOCULATION PROCEDURE with SAWDUST SPAWN for Drill & Fill Method
					</h2>
					<p id="p" align="justify">
						When you receive your spawn examine it carefully. The bag should be
						completely sealed. The spawn itself should be a relatively uniform
						mixture of brownish sawdust with whitish mycelium growing through
						it. There should be no green, grey, black or other unusual areas on
						the spawn. The spawn you have received is at its peak for use as an
						inoculant. If you do not intend to use it within the next 5 days
						refrigerate it at 2-50C. The spawn will keep for a few months,
						however immediate use is recommended because as the spawn
						matures/ages it becomes more and ‘bound’ by the mycelial hyphae. It
						will become very difficult to handle and use. Cold temperature will
						inhibit the development of the mycelial hyphae. When the hyphal
						development progresses too far it can be difficult to break the
						spawn apart so that it is loose and friable and with a small enough
						particle size to work your inoculation tool and to insert the spawn
						into the holes in your logs; ‘bound’ spawn can be processed in a
						blender or food processor if necessary. However, we recommend that
						the spawn be used as soon as possible - within 5 days of receipt is
						best. If the spawn has been stored refrigerated, bring it to room
						temperature for a few days before using it. <br />
						<br />
						Handle the spawn to minimize exposure to ubiquitous contaminants
						such as bacteria and especially mould spores and to prevent
						dehydration. Use the following rules to protect the spawn:
						<br />
						<ul>
							<li>
								before opening, wipe the outside of the bag with a dilute bleach
								solution and carry the bag to your inoculation site in a clean
								bucket; keep the bag in a cool, shaded location
							</li>
							<li>
								wash your hands and periodically rinse tools coming in contact
								with the spawn
							</li>
							<li>
								before opening and immediately before inoculation gently break
								the spawn up through the bag by manipulating the bag with your
								fingers; you can also shake/agitate the bag so spawn is loose
								and friable; the ‘grain’ size of the spawn should be quite small
								and it should break up fairly easily and not form clumps that
								are difficult to break apart; it should be moist but not wet;
								‘bound’ or ‘clumped’ spawn can be pulsed in a food processor
							</li>
							<li>
								schedule inoculation sessions to allow enough time to completely
								use the spawn in a particular bag in a single session and/or
								remove spawn from the bag in small batches as you use it,
								perhaps in a clean plastic container; keep the bag closed after
								each removal and never put any spawn back in the bag for use
								later
							</li>
						</ul>
						<br />
						<br />
						This spawn is used to inoculate hardwood logs that were cut when
						dormant – after leaf fall and before leaf bud and that have rested
						for at least 1 month. The method described here is ‘drill & fill’
						method. You need a drill with a 12.5 or a 13.5 mm (or ½ inch) bit
						and an inoculation tool. A depth stop on the drill bit is helpful in
						order to drill holes to a depth of 1 and 1/8th inches or 28.5 mm.
						The holes MUST be sealed with cheese or bees wax or with a Styrofoam
						tab to prevent spawn from drying out. Drill holes 10-15 cm (4-6
						inches) apart, in ‘offset’ rows that are 5 - 7.5 cm (2 - 3 inches)
						apart. The holes should form a diamond pattern as in the diagram
						below. Extra holes can be drilled around knots or similar
						irregularities in the log. Drilling more holes with a larger bit
						will speed the spawn run; one expert recommends doubling the
						inoculation rate for Lion’s Mane.
					</p>
					o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o
					<br />
					o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o
					<br />
					o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o
					<br />
					<br />
					<p id="p" align="justify">
						<div
							style={{
								border: "0px solid #fff",

								display: "flex",
							}}
						>
							<div style={{ width: "20%" }}></div>
							<div
								style={{
									border: "0px solid red",
									position: "relative",
									paddingright: "20px",
									width: "fit-content",
								}}
							>
								<img alt="" src={TreeTrunkwithHoles} />
							</div>
							<div style={{ width: "20%" }}></div>
							<div
								style={{
									border: "0px solid #fff",
									position: "relative",
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									width: "fit-content",
								}}
							>
								<img
									width="250px"
									src={inoculatorinhand}
									alt="Hand with inoculator"
								/>
							</div>
						</div>
						<p align="left">
							<i>
								Fill holes with loose sawdust spawn using an inoculation tool.
								You can also use a small funnel and tamping rod to insert the
								spawn into the holes, but an inoculation tool will speed up your
								work
							</i>
						</p>
						<br />
						<br />
						You can push the inoculation tool into the bag of friable spawn to
						fill the hollow tube, or you can place spawn in a clean, flat
						container to the depth of 1&1/2” so that you can push the tool into
						the spawn and fill it to the mark on the tube. This will give you
						the right amount of spawn to fill the hole. Then plunge the spawn
						into the hole. Do not overfill the holes – spawn should be below the
						level of the bark; do not pack the spawn down.
						<br />
						<br />
						You must seal the holes as quickly as possible. Working in a team is
						ideal – one drilling, one filling, and one sealing; or one worker
						drilling and 2 workers filling and sealing (drilling is fast;
						filling & sealing is slower) or some other practical division of
						labour. You can set up a workstation that allows you to roll the
						logs along to facilitate the work.
						<br />
						<br />
						<br />
						<img alt="workbench" src={workbench} />
						<br />
						<i>An easy to set up work station, for log inoculation</i>
						<br />
						<br />
						Holes are sealed with wax (cheese wax or bees wax or mix) or with a
						Styrofoam cap. If using caps the drill bit size must match the size
						of the cap. Styrofoam caps come in rolls - simply break off
						individual caps with your fingers and insert into the hole. If using
						wax, you will need to melt it; to do this ‘in the field’ use a
						Coleman camping stove or a burner with a butane cylinder; use a tin
						can or other metal vessel in which to heat the wax. Apply with wax
						dobbers or with very small sponge paint brushes. Use safety
						precautions when using gas/butane burners. <br />
						<br /> Following inoculation, the mycelium will colonize the
						sapwood. During this “spawn run” stack your logs off the ground in a
						shaded area in a log cabin style/crib arrangement with space around
						each log to allow ventilation.
						<br />
						<br />
						<img alt="" src={Logs_stacked} />
						<br />
						<i>Logs stacked for incubation/spawn run under forest canopy</i>
						<br />
						<br />
						The ideal location for your logs during incubation/spawn run is
						under the forest canopy in a well-drained area with good air
						movement. For the first 4 weeks of the spawn run you may have to
						water/soak the logs once a week unless there is sufficient rainfall
						and/or the logs were quite moist (inside) when inoculated. Sprinkle
						logs for 18 to 24 hours and then let them dry out. The objective
						during the spawn run is to keep the moisture level inside the logs
						high but allow the outside to dry so as not to rot the bark off.
						Logs should not be exposed to full sun during the spawn
						run/incubation phase. During winter months keep logs shaded. Even if
						logs are under the forest canopy they may need to be covered with
						shade cloth or with some form of breathable covering (cedar boughs)
						so that they are not exposed to full/direct sun for hours at a time,
						especially during the late afternoon.
						<br />
						<br />
						<img alt="logs_end" src={logs_end} />
						<br />
						<i>Mycelium showing at the ends of logs on the sapwood ring</i>
						<br />
						<br />
						The logs will “fruit” 6 months to 1 year following inoculation
						depending on factors such as when logs were cut, how hard the wood
						is, how moist the sapwood is, and temperature and rainfall patterns
						during the spawn run. Fruiting generally starts the spring following
						inoculation (1 year later). You should be able to see mycelium
						showing at the end of the log in the sapwood ring – as in the
						picture above.
						<br />
						<br />
						Before the logs begin fruiting they must be stood on end to allow
						the mushrooms to form and emerge and to facilitate harvesting. See
						“Log Management Following Spawn Run” [insert link to that sub
						menu/tab] https://mycosource.com/log-management-following-spawn-run
					</p>
				</div>
			</div>
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="spawncontainer"
					style={{
						top: "30px",
						position: "relative",
						background: "#474747",
						height: "fit-content",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Logs</h2>
					<p id="p" align="justify">
						Inoculated logs for your backyard or cottage. See the picture of
						Shiitake below. Please note that we do not provide raw logs for
						inoculation. If you are planning to do an inoculation project you
						must secure your own logs. Logs should be cut after leaf fall in the
						autumn and before leaf bud in the spring; they can ‘rest’ over the
						winter laying off the ground on a skid or other device to keep them
						off the ground, covered with snow. Do not leave exposed logs in full
						sunlight during the winter — cover with burlap or a tarp that can
						‘breathe’.
					</p>
				</div>
			</div>

			<div
				style={{
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="indoor-cultivation-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						References and Further Information
					</h2>
					<p id="p" align="justify" style={{ padding: "0 20px 0" }}>
						Cornell University extension project - Sustainable Agriculture
						Research Education (SARE) - has published an excellent guide about
						log-based inoculation and management with pictures and drawings.
						<br />
						<br />
						<a
							target="_new"
							href="https://projects.sare.org/wp-content/uploads/977907shiitake-bmp-3-11-final-for-print-hires.pdf"
						>
							“Best Management Practices for Log-based Shiitake Cultivation in
							the Northeastern United States”.
						</a>
						<br />
						<br />
						The ‘drill & fill’ technique described herein, and in the document
						above, are specific to Shiitake cultivation but are applicable to
						Oyster and Lion’s Mane cultivation. One expert recommends doubling
						the inoculation rate for Lion’s Mane by using a larger bit and/or
						drilling more holes that are more closely spaced. Find videos here:
						<br />
						<br />
						<a
							target="_new"
							href="https://smallfarms.cornell.edu/projects/mushrooms/outdoor-production"
						>
							{" "}
							Outdoor Production - Cornell Small Farms
						</a>{" "}
						<br />
						<br />
						Another inoculation method for using larger diameter logs is the
						‘totem’ method. As the name suggests the logs are cut into sections,
						packed with spawn, and then re-assembled into a totem structure. For
						this method you only need a chainsaw, a leaf litter kraft paper bag,
						and the bag of sawdust spawn.
						<br />
						<br />
						The following document focuses on growing Lion’s Mane and Oyster
						mushrooms using the totem method. Search for “Totem Method for
						Cultivating Oyster and Lion’s Mane Mushrooms”. The following pdf
						icon takes you to a document about the totem method from the Cornell
						website or copy and paste the address into your browser or highlight
						the address and press ctrl + click <br />
						<br />
						<a
							target="_new"
							href="https://cpb-us-e1.wpmucdn.com/blogs.cornell.edu/dist/d/958/files/2012/12/Totems-1thqlmj.pdf"
						>
							Totems.doc (cpb-us-e1.wpmucdn.com)
						</a>
						<br />
						<br />
						The following link takes you to the Cornell University website to
						find videos and related documents.{" "}
						<a
							target="_new"
							href="https://smallfarms.cornell.edu/projects/mushrooms/outdoor-production"
						>
							Cornell University
						</a>
						<br />
						<br />
						Search online for the folowing videos:
						<ul>
							<li>
								Growing Oyster mushrooms with the totem method (Field & Forest
								Products Mushrooms)
							</li>
							<li> Growing Lion’s Mane mushrooms using the totem method </li>
							<li> Introduction to Totem Grown Mushrooms (Forest Farming)</li>
						</ul>
					</p>
				</div>
			</div>

			<div
				style={{
					margin: "30px 10px 30px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>
			<div style={{ height: "50px" }}></div>
		</div>
	);
};
export default CultivationSawdust;

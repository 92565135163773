import React from "react";
import { onReadMore } from "./Functions";

const Nutrition = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="medicinal-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						/* height: "180px", */
						height: "fit-content",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Medicinal Mushrooms</h2>
					<p id="p" align="justify">
						Unlocking the Power of Mushrooms for Health and Wellness Mushrooms
						have long been revered for their potential health benefits across
						various cultures and traditions. From the ancient wisdom of
						Traditional Chinese Medicine to modern scientific research, several
						mushroom varieties have captured the spotlight for their remarkable
						therapeutic properties. Reishi Mushroom: A Staple in Traditional
						Chinese Medicine Reishi, scientifically known as Ganoderma lucidum,
						stands out as one of the most revered mushrooms in Traditional
						Chinese Medicine (TCM). Renowned for its adaptogenic properties,
						Reishi has been extensively studied for its potential to support
						immune function, reduce inflammation, and promote overall
						well-being. TCM practitioners often prescribe Reishi to alleviate
						stress, enhance vitality, and promote longevity.{" "}
						<p>
							Modern research has delved into Reishi's bioactive compounds,
							including triterpenes, polysaccharides, and antioxidants, which
							are believed to contribute to its therapeutic effects. From
							cardiovascular health to cancer prevention, the versatile benefits
							of Reishi continue to garner attention in both traditional and
							integrative medicine practices. Shiitake Mushroom: A Japanese
							Culinary and Medicinal Marvel Shiitake (Lentinula edodes) has
							earned its place not only as a culinary delight but also as a
							potent medicinal mushroom, particularly in Japan.
						</p>{" "}
						Rich in essential nutrients and bioactive compounds, Shiitake has
						been studied extensively for its immune-modulating, antimicrobial,
						and cholesterol-lowering properties. Research into Shiitake's health
						benefits has revealed its potential to boost immune function,
						support cardiovascular health, and even aid in weight management.
						The presence of beta-glucans, polysaccharides, and other bioactive
						molecules in Shiitake underscores its role as a functional food with
						therapeutic potential. Oyster Mushroom (Pleurotus ostreatus): A
						Nutrient-Rich Fungus Oyster mushrooms, scientifically known as
						Pleurotus ostreatus, are valued not only for their culinary
						versatility but also for their nutritional profile and potential
						health benefits.
						<p>
							These mushrooms boast a rich array of vitamins, minerals, and
							antioxidants, making them a valuable addition to any diet. Studies
							have highlighted the potential of Oyster mushrooms to support
							immune function, regulate blood sugar levels, and promote heart
							health. Additionally, their high protein content and low calorie
							count make them an attractive option for individuals seeking to
							enhance their nutritional intake without excess calories.
							Incorporating mushrooms like Reishi, Shiitake, and Oyster into
							your diet can be a flavorful and convenient way to tap into their
							health-promoting properties. Whether enjoyed in soups, stir-fries,
							or supplements, these fungi offer a natural and holistic approach
							to supporting overall health and wellness.
						</p>
					</p>
				</div>
			</div>

			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="books-container"
					style={{
						position: "relative",
						background: "#474747",
						height: "fit-content",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Books</h2>
					<p id="p" align="justify">
						We no longer carry books because you can order via Amazon.com and
						other internet sites. There are many excellent wild mushroom
						identification guides available as well as books about Kombucha and
						medicinal mushrooms. For mushroom cultivation outdoors on hardwood
						logs we recommend “The Shiitake Growers Handbook” by Przybylowicz
						and Donoghue as well as “Growing Shiitake in a Continental Climate”
						by Krawcyk and Kozak (currently out of print). “Growing Gourmet and
						Medicinal Mushrooms” by Paul Stamets contains chapters detailing
						indoor and outdoor cultivation on both Shiitake and Oyster. There
						are internet sites with quite a bit of information on mushroom
						cultivation. For information about kombucha we recommend “Kombucha:
						healthy beverage from the Far East” by Gunther Frank as well as
						“Kombucha Rediscovered!” by Klaus Kaufmann (Alive Books). You will
						find comprehensive information about Kombucha on the internet at
						www.kombucha.com and other sites.
					</p>
				</div>
			</div>

			{/* <div
				id="medicinal"
				style={{
					position: "relative",
					bottom: "20px",
					float: "right",
					right: "10px",
					width: "90px",
					height: "fit-content",
					background: "red",
					cursor: "pointer",
					borderRadius: "5px",
				}}
				onClick={(e) => onReadMore(e, 180)}
			>
				Read more
			</div> */}
		</div>
	);
};
export default Nutrition;

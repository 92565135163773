// Products.js
import React from "react";
import { onReadMore } from "./Functions";

import PinkOyster300 from "./images/Pink-Oyster-300x200.jpg";
import PerlOyster300 from "./images/Pearl-Oyster-300x200.jpg";
import FruitingLog from "./images/Fruiting-log-closeup1-300x200.jpg";

function Products() {
	return (
		<div className="es-position">
			<div
				id="ordering-procedures"
				style={{
					top: "30px",
					position: "relative",
					background: "#474747",
					height: "fit-content",
					overflow: "hidden",
				}}
			>
				<h2 style={{ textAlign: "center" }}>Ordering Procedures</h2>
				<p id="p" align="justify">
					<b>How to Place an Order with Mycosource Inc.</b>
					<br />
					<br />
					Send us an email with the items you want to{" "}
					<a href="mailto:office@mycosourc.com">office@mycosourc.com</a>.
					<br />
					<br />
					Include your name and full shipping address with postal code and any
					specific delivery details.
					<br />
					<br />
					We will respond with a quote; all prices are in Canadian dollars;
					equipment and supplies are subject to HST. Once the order is confirmed
					we will respond with an invoice that is payable by cheque or
					e-transfer; payment details will come with the invoice.
					<br />
					<br />
					When the order is paid we will send a receipt by email.
					<br />
					<br />
					<br />
					<b>Shipping</b>
					<br />
					<br />
					The invoice will show the shipping date and method of shipment. We
					will begin shipping when the weather permits in mid to late April and
					will continue shipping through May and June depending on the date that
					the order was received.
					<br />
					<br /> We ship via Canada Post. We generally ship via regular parcel
					post service but can ship via expresspost service as necessary. We can
					ship via UPS if requested. Shipping charges will be shown on the
					invoice – we charge the exact Canada Post or UPS rate for your address
					plus a handling charge in order to keep shipping charges as low as
					possible.
					<br />
					<br /> When the order is shipped you will get notification with
					tracking #. Depending on your address the package will either be ‘left
					at door’ or will be ‘card for pick up’. Track at
					www.canadapost.ca/track Special requests to have spawn delivered early
					will be dealt with on a case-by-case basis.
					<br />
					<br /> Special requests for early shipping may not be possible due to
					our production schedule and weather. UPS shipping may be necessary to
					have spawn arrive as requested. As previously stated, spawn is a
					living organism and cannot be subject to freezing temperatures or
					extended exposure to direct sun. Spawn that is delayed and spoiled
					during shipping due to an error made by Mycosource Inc. will be
					replaced free of charge. Spawn that is delayed and spoiled due to an
					error by the customer will be replaced, if possible, at customer
					expense.
					<br />
					<br />
					<br />
					<b>Pick-Up & Demo Days</b>
					<br /> We have Pick-Up and Demo Days at ‘Fun Guy Farm’ – only for
					pre-paid orders. Let us know when you place the order that you would
					like to pick up and we will provide directions to the farm as well as
					the pick-up dates & times so you can decide if this will work for you.
					FYI the farm is located 65 km NE of Toronto in Durham Region –
					directly east of Stouffville and directly south of Goodwood.
					<br />
					<br />
					Contact us: at{" "}
					<a href="mailto:office@mycosourc.com">office@mycosourc.com</a>
					<br />
					<br /> 1-416-963-5520
				</p>
			</div>

			<div style={{ height: "fit-content" }}>
				<div
					style={{
						top: "40px",
						margin: "10px 10px 20px 10px",
						position: "relative",
						padding: "10px",
						background: "#2d2d2d",
						height: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Custom sterilization</h2>
					<div className="most-popular-container">
						<p align="justify" style={{ background: "#2d2d2d" }}>
							We specialize in custom sterilization of bags containing your
							unique substrate recipe, including grain, sawdust, and more.
							Minimum order quantities apply. Please contact us via email at
							office@mycosource.com for inquiries.
							<br />
							<br />
						</p>
					</div>
				</div>
			</div>

			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="spawncontainer"
					style={{
						position: "relative",
						background: "#474747",
						height: "180px",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Spawn</h2>
					<p id="p" align="justify">
						Spawn is the name for the mushroom inoculant which is a culture of
						fungal mycelium grown out on various media such as sawdust. For log
						growers we have bulk sawdust spawn. For sawdust block growers we
						have grain or sawdust spawn.
					</p>
				</div>
			</div>

			<div style={{ height: "fit-content" }}>
				<div
					style={{
						position: "relative",
						padding: "10px",
						background: "#474747",
						height: "100%",
					}}
				>
					<div className="most-popular-container">
						<div>&nbsp;</div>
						<div style={{ width: "100%" }}>
							<div
								style={{
									display: "flex",
									height: "fit-content",
									width: "100%",
									justifyContent: "center" /* horizontally center */,
									alignItems: "center" /* vertically center */,
								}}
							>
								<div>
									<img
										style={{ height: "auto" }}
										alt=""
										width="100%"
										src={PinkOyster300}
									/>
								</div>
								<div>
									<img
										style={{ height: "auto" }}
										alt=""
										width="100%"
										src={PerlOyster300}
									/>
								</div>
								<div>
									<img
										style={{ height: "auto" }}
										alt=""
										width="100%"
										src={FruitingLog}
									/>
								</div>
							</div>
						</div>
					</div>

					<div style={{ height: "30px" }}></div>
				</div>
			</div>
		</div>
	);
}

export default Products;
